// ProtectedRoute.js

import React from "react";
import {  Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = ({ isAuthenticated, token }) => {
  // if (!isAuthenticated && !token) {
  //   return <Navigate to="/update" replace />;
  // }
  return <Outlet />;
};

export default ProtectedRoute;
